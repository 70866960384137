:root{
    --green-ba:#4da357;
    --gray-ba:#F1F2F6;
    --white-ba:#FFFFFF;
    --gray-txt-ba:#A0AEC0;
    --dark-txt-ba:#2D3748;
    --light-txt-ba:#718096;
    --gray-user-ba:#1F2937;
    --fade:rgba(45, 55, 72, 0.4);
    --gray-back-btn: #FAFAFB;
    --gray-search: #E5E7EB;
    --gray-search-login: #F2F2F2;
    --purple-ba: #BF8FCF;
    --orange-ba: #FF9029;
    --gray-btn-table: #CACACA;
    --warning: #FBD472;
    --blue-ba: #71C8F9;
    --green-ba-ht: #7ACD84;
    --hr: #E5E5E5;
    --blue-reclut: #5AA0C7;
    --gray-pb: #818181;

}