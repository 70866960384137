button{
    font-family: Poppins;
    font-size: 13px !important;
}


.btn-outline-ba {
    background-color: var(--gray-back-btn);
    color: var(--green-ba);
    font-size: 12px;
    font-weight: 500;
}


.btn-outline-ba:hover {
    color: var(--green-ba);
}

.btn-outline-ba-active {
    background-color: var(--green-ba);
    color: white;
}

.btn-left-ba {
    border-radius: 32px 0px 0px 32px;
}

.btn-right-ba {
    border-radius: 0px 32px 32px 0px;
}

.btn-content-ba {
    display: block;
}

.btn-olg-ba {
    background-color: var(--gray-back-btn);
    color: var(--green-ba);
    font-size: 12px;
    font-weight: 500;
    border: solid 1px var(--green-ba);
    border-radius: 8px;
}

.excel-icon {
    margin-left: 12px;
}

.btn-ba {
    background-color: var(--green-ba);
    color: white;
}

tr {
    vertical-align: middle;
}

.div-btns-ent {
    display: contents;
}