
@font-face {
  font-family: 'Helvetica';
  src: local("Assets/fonts/Helvetica/Helvetica.ttf");
}


.rdrDateDisplay {
  display: none !important;
}

.rdrDateDisplayWrapper {
  display: none !important;
}

/*  ESTILOS DATAGRID  */

.css-18lolh3-MuiDataGrid-root,
.css-fimgsr {
  border: none !important;
}

.css-okt5j6-MuiDataGrid-columnHeaders,
.css-fimgsr .MuiDataGrid-columnHeaderTitleContainer {
  color: var(--gray-txt-ba) !important;
  font-family: Helvetica !important;
  font-size: 8px !important;
  align-items: center !important;
}

.css-18lolh3-MuiDataGrid-root .MuiDataGrid-iconSeparator,
.css-fimgsr .MuiDataGrid-iconSeparator {
  color: white !important;
}

.css-18lolh3-MuiDataGrid-root .MuiDataGrid-row,
.css-fimgsr .MuiDataGrid-cell--textLeft {
  font-family: 'Helvetica' !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 12px !important;
  line-height: 140% !important;
  /*text-align: center !important;*/
  color: var(--dark-txt-ba) !important;
}

.css-opb0c2{
  flex-direction: column-reverse !important;
}

.css-rtrcn9-MuiTablePagination-root,
.css-1chpzqh {
  font-size: 13px !important;
  color: var(--green-ba) !important;
  font-weight: 600 !important;
}

.css-204u17-MuiDataGrid-main {
  flex-direction: column-reverse !important;
}
/*.MuiDataGrid-root .MuiDataGrid-cell{
  white-space: revert !important;
}*/

.checknombres {
  color: var(--green-ba) !important;
  margin-left: 10px !important;
}

.m-over {
  cursor: pointer;
}


.ms-n5 {
  margin-left: -40px;
}

body {
  background-color: var(--gray-ba);
  zoom: 80% !important;
}

.nav-link,
.nav-link:hover {
  color: var(--gray-txt-ba);

}

.nav-link.active {
  background-color: var(--green-ba) !important;
  border-radius: 8px;
  width: 228px;
  height: 42px;
}

.espaciador {
  margin-right: 15px;
}

button#bntCrearSolicitud {
  background-color: var(--green-ba) !important;
  margin-left: 10px;
  font-size: 14px;
  font-family: 'Poppins';
  font-weight: 500;
}

button#btnDescargar:hover {
  background-color: var(--green-ba) !important;

}

button#btnDescargar {
  border-color: var(--green-ba) !important;
  font-size: 14px;
  font-family: 'Poppins';
  font-weight: 500;
  margin-left: 15px;

}

.FondoBlanco {
  background-color: var(--white-ba) !important;
  border-radius: 15px;
  min-height: 100vh;
  display: flow-root;
}

.containerPrincipal {
  padding: 0 60px;
}

.table>tbody {
  border-top: none !important;
}

.tablaHeader {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 150%;
  text-align: center;
  color: var(--gray-txt-ba);
}

.tablaCelda {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 140%;
  text-align: center;
  color: var(--dark-txt-ba);
}

.textoClaro {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  color: var(--light-txt-ba);
}

.btn.btn-rounded {
  border-radius: 50px;
  margin-right: 10px;
}

.btn.btn-rounded:hover {
  background-color: var(--green-ba) !important;
}

.btn-table {
  background-color: var(--green-ba);
  color: white;
  height: 25px;
  border: none;
  border-radius: 8px;
  font-size: inherit !important;
}

/*
.textoSuperior{
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 140%;
  color: var(--dark-txt-ba);
}

.pNormal{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  color:var(--dark-txt-ba);
}

.floatCal {
  float: left;
  margin-left: -35px;
  margin-top: -25px;
  position: relative;
  z-index: 1;
  color: var(--green-ba);
}*/


.ResumenSolicitud {
  background-color: var(--white-ba) !important;
}

.btn.btn-rounded {
  border-radius: 50px;
  margin-right: 10px;
}

.btn.btn-rounded:hover {
  background-color: var(--green-ba) !important;
}

.textoSuperior {
  /*font-family: Helvetica, sans-serif;*/
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 140%;
  color: var(--dark-txt-ba);
}

.pNormal {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  color: var(--dark-txt-ba);
}

.bGreen {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  color: var(--green-ba);
}

.floatCal {
  /*float: left;
  margin-left: -35px;
  margin-top: -25px;
  position: relative;
  z-index: 2;*/
  color: var(--green-ba);
}

.verdeTitle {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.1px;

  color: var(--green-ba);
}

.grisTitle {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.1px;

  color: var(--gray-txt-ba);
}

.negroTitle {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 22px;
  letter-spacing: 0.1px;
  color: var(--gray-user-ba);
}

.pFade {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  color: var(--fade);
}

.pFadeB {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 140%;
  color: var(--fade);
}

.div-btns {
  text-align: right;
}

h3 {
  font-family: Helvetica, sans-serif;
}

label {
  font-family: Poppins, sans-serif;
}

.lbl-hr {
  font-weight: 700;
}

.span-title {
  font-size: 20px;
  font-weight: 400;
  font-family: Poppins, sans-serif;
}

.numberV {
  color: var(--green-ba);
  font-weight: 700;
  margin-right: 5px;
}

ul {
  list-style: none;
}

.listaSugerencias {
  margin-left: -20px;
  margin-top: 10px;
  ;
}

ul li {
  cursor: pointer;
}

.lbl-pag {
  font-size: 13px;
  color: var(--green-ba);
  font-weight: 600;
}

select {
  border: none;
  color: var(--green-ba);
}

.bg-search {
  background-color: var(--gray-search);
  border: none;
  color: var(--gray-user-ba) !important;
}

.bg-search-login {
  background-color: var(--gray-search-login);
  color: #00000059;
  font-size: 12px;
  height: 48px;
  border: 0.5px solid #E5E5E5;
}

.btn-ba-login {
  background-color: var(--green-ba);
  color: white;
  height: 40px;
  font-weight: 700;
  font-family: Poppins, sans-serif;
  font-size: 15px;
}


input,
textarea {
  font-family: FontAwesome, "Open Sans", Poppins, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none !important;
}

.color-green-ba {
  color: var(--green-ba) !important;
}

.btn-purple {
  background-color: var(--purple-ba);
  color: white;
  width: 88px;
  font-size: 10px !important;
}

.btn-orange {
  background-color: var(--orange-ba);
  color: white;
  width: 88px;
  font-size: 10px !important;
}

.btn-blue-ba {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(0deg, #71C8F9, #71C8F9);
  color: white;
  width: 88px;
  font-size: 9px !important;
}

.btn-gray {
  background-color: var(--gray-btn-table);
  color: white;
  width: 88px;
  font-size: 10px !important;
}

.margin-loader {
  text-align: center;
  margin-top: 20%;
}

.color-warning {
  color: var(--warning);
}

.color-red {
  color: red;
}

.text-label {
  font-family: "Helvetica", serif !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 12px !important;
  line-height: 140% !important;
  text-align: center !important;
  color: var(--dark-txt-ba) !important;
  margin-left: 12px;
}

.border-dashed {
  border-style: dashed;
  border-color: #f4f4f4;
  padding: 20px;
}

.historialSolicitud {
  color: var(--purple-ba);
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 140%;
}

.historialReclutamiento {
  color: var(--blue-reclut);
  font-weight: bold;
}

.historialCapacitacion {
  color: var(--orange-ba);
  font-weight: bold;
}

.historialCertificacion {
  color: var(--green-ba-ht);
  font-weight: bold;
}

.historialIngreso {
  color: var(--dark-txt-ba);
  font-weight: bold;
}

.fondoLogin {
  background-image: url("./Assets/img/loginbkg.jpg");
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: inherit;
}

.loginContainer {
  margin: 23% 11%;
  font-family: Poppins, sans-serif;
}

.fondoLoginForm {
  background-color: var(--white-ba) !important;
}

#eyepassword {

  cursor: pointer;
}

.form-label-login {
  font-family: Poppins, sans-serif;
  font-size: 11px;
}

.hr-login {
  color: var(--hr);
  margin-top: 25px;
  height: 2px !important;
}

.lblError {
  color: red;
  font-size: 11px;
  font-family: Poppins, sans-serif;
}

.color-white {
  color: white;
}

.lblCancel {
  color: #EE0000;
  font-family: Helvetica, sans-serif;
  font-weight: 700;
}

.lblHistorico {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
}

.lblHistoricoHoras {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;

  color: #2D3748;
}

.btn-group.special {
  display: flex;
}

.special .btn {
  flex: 1
}

.barra {
  width: 200px;
}

.css-14el2xx-placeholder {
  font-family: Poppins, sans-serif;
}

/*Quita las flechas en input type number*/
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root {
  font-family: Poppins, sans-serif !important;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input,
.css-1uvydh2 {
  height: 5px !important;
}

.btn-outline-success {
  color: var(--green-ba) !important;
  border-color: var(--green-ba) !important;
}

.botonlista {
  min-width: 100px;
  cursor: auto !important;
}

.btn-question {
  margin-left: 5px;
  width: auto;
  text-align: center;
  color: black;
  height: 35px;
  background-color: var(--gray-search);
  font-weight: 100 !important;
}

.btn-outline-success:hover {
  background-color: white !important;
}

.xls_logo_img {
  margin-left: 10px;
}

.popup-content {
  margin-top: 0px !important;
  min-height: 300px;
  border-radius: 0 0 10px 10px;
}

.titulopopreporte {
  font-family: 'Helvetica';
  color: var(--green-ba);
  font-size: 18pt;
  font-weight: 700;
}

.botonContenedorL {
  width: 140px;
  min-height: 140px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  background-color: #FAFAFB;
  border-radius: 4px;
  margin-left: calc(100% - 140px);
}

.botonContenedorR {
  width: 140px;
  min-height: 140px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  background-color: #FAFAFB;
  border-radius: 4px;
  margin-left: 0px;
}

.popDesktop {
  font-size: 40pt;
  margin-top: 30px;
  margin-left: 40px;
  color: var(--green-ba);
}

.texticopop{
  margin-left: 40px;
  font-weight: 700;
  
}

.progress{
  height: 0.5rem !important;
}

.bg-success {
  background-color: var(--green-ba) !important;
}

.lbl-pb{
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: right;
  color: var(--gray-pb);
}

.react-datepicker__header {
  background-color: #FFFFFFFF !important;
  border-bottom: 1px solid var(--green-ba) !important;
}

.swal2-styled.swal2-confirm {
  background-color: var(--green-ba) !important;
}


.swal2-styled.swal2-cancel{
  background-color: var(--gray-pb) !important;
}

.textoUsuario {
  font-family: Poppins, sans-serif !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  color: #1F2937 !important;
}

.css-1jbbcbn-MuiDataGrid-columnHeaderTitle, .css-cc8tf1{
  white-space: break-spaces !important;
  font-weight: initial !important;
  font-size: 9px !important;
  line-height: initial !important;
  text-align: center !important;
}

/*.css-18lolh3-MuiDataGrid-root .MuiDataGrid-cell--textLeft {
  justify-content: center !important;
}*/

.css-18lolh3-MuiDataGrid-root .MuiDataGrid-cell, .css-fimgsr .MuiDataGrid-cell{
  white-space: break-spaces !important;
}

.dropdown-item {
  color: var(--green-ba);
}

.btn-success{
  background-color: var(--green-ba);
  border-color: var(--green-ba);
}

.css-195y93z-MuiButtonBase-root-MuiPickersDay-root.Mui-selected, .css-bkrceb-MuiButtonBase-root-MuiPickersDay-root.Mui-selected,
.css-bkrceb-MuiButtonBase-root-MuiPickersDay-root.Mui-selected:hover,
.css-ub1r1.Mui-selected, .css-ub1r1.Mui-selected:hover{
  background-color: var(--green-ba) !important;
}

:checked{
  background-color: var(--green-ba) !important;
}

.swal2-loader {
  border-color: var(--green-ba) transparent var(--green-ba) transparent !important;
}

table{
  min-width: -webkit-fill-available;
  font-size: medium;
}

th {
  font-size: 10px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0em;
  color: #A0AEC0;
}

td{
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #2D3748;
}

tr {
  border-bottom: 1px solid #E2E8F0;
}

.btn-error {
  background-color: #be0f20;
}

.btn-status {
  border-radius: 15px;
  padding: 5px 20px;
  border: 1px solid rgba(252, 125, 116);
  background-color: rgba(252, 125, 116);
  color: #FFFFFF;
  cursor: auto !important;
}

.no-cursor{
  cursor: auto !important;
}
.popup-content {
min-height: 100px !important;
}

